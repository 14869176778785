import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ignoreErrors } from './errors';
import { runInteraction } from './interactions';
import { captureViewerException } from './viewer-errors';

export function withInteraction<P extends any[], T>(
  flowAPI: ControllerFlowAPI,
  name: string,
  action: (...args: P) => T | Promise<T>,
  notMonitoredErrorClasses: any[] = [],
): (...args: P) => Promise<T> {
  return renameFunction('monitored ' + action.name, async (...args: P) =>
    runInteraction(flowAPI, name, () => action(...args), notMonitoredErrorClasses),
  );
}

export function withMonitoring<P extends any[], T>(
  flowAPI: ControllerFlowAPI,
  notMonitoredErrorClasses: any[],
  action: (...args: P) => T | Promise<T>,
) {
  return renameFunction('monitored ' + action.name, async (...args: P) =>
    monitorErrors(flowAPI, notMonitoredErrorClasses, () => action(...args)),
  );
}

export async function monitorErrors<T>(
  flowAPI: ControllerFlowAPI,
  notMonitoredErrorClasses: any[],
  action: () => T | Promise<T>,
) {
  try {
    return await action();
  } catch (e) {
    if (!notMonitoredErrorClasses.find((obj) => e instanceof obj)) {
      captureViewerException(flowAPI, e, {
        context: { site: { url: flowAPI.controllerConfig.wixCodeApi.location.url } },
      });
    }
    throw e;
  }
}

export function withIgnoredErrors<P extends any[], T>(
  action: (...args: P) => T | Promise<T>,
): (...args: P) => Promise<T | void> {
  return renameFunction('silent ' + action.name, async (...args: P) => ignoreErrors(() => action(...args)));
}

/**
 * Modifies the name of the function.
 */
function renameFunction<T extends Function>(newName: string, fn: T): T {
  Object.defineProperty(fn, 'name', { value: newName, writable: false });
  return fn;
}
